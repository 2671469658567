import { useState } from 'react';

import spinner from '../../../../src/img/722.gif';
import steam from '../../../img/steam.png';

import './form.scss';

const Form = () => {
    const {0: redirectOnPay, 1: setStatus} = useState(false)
    const {0: price, 1: setPrice} = useState(300);
    const {0: login, 1: setLogin} = useState('???')
    const {0: alert, 1: setAlert} = useState(false)

    const inputChange = (e) => {
        const value = e.target.value;
        if ( value === '' || (parseInt(value) >= 0 && parseInt(value) <= 14999)){
            return setPrice(parseInt(value))
        }
    }

    const login_validate = () => {
        if (login.length > 28){
            return `${login.substring(28, '...')}...`
        }
        return login
    }

    const priseAdd = (add) => {
        const currentPrise = parseInt(price);
        const newPrise = currentPrise + add;
        const limitedPrise = Math.min(newPrise, 14999);

        if(Number.isNaN(currentPrise)){
            return setPrice(add + 300)
        }
      
        return setPrice(limitedPrise);
    }

    const oldp = Math.round(price * 1.035)
    const newp = Math.round(price * 1.025)
    const clazz = alert ? 'alert active' : 'alert'
    const clazzHand = redirectOnPay ? 'form__handler activeHandler' : 'form__handler';

    const postData = async(url,data) => {
        const res = await fetch(url, {
            method: 'POST',
            body: data
        });
    
        return await res.text();
    };
    
    const submitForm = (e) => {
        e.preventDefault();

        const data = new FormData();

        data.append('price', newp);

        if(newp < 300){
            setAlert(true)
            setPrice(300)
            setTimeout(() => {
                setAlert(false)
            },3000)
        }else{
            setStatus(true);
            postData('../../services/pay.php', data)
            .then(data => window.location.href = data)
        }
    }

    return(
        <main className='product'>
            <form 
            onSubmit={e => submitForm(e)}
            className='form'>
                <div className='form__inner'>
                    <div className='form__block-info'>
                        <div className='form__block-info-inner'>
                            <img alt='Пополнить балланс Steam' src={steam} className='form__steam'/>
                            <h1>Пополнить Steam</h1>
                        </div>
                    
                        <label>
                            <span>Логин Steam</span>
                            <input autoComplete='off' type="text" minLength="2" maxlength="56" placeholder='Ваш логин'  onChange={e => setLogin(e.target.value)} required/>
                        </label>

                        <label>
                            <span>Сумма</span>
                            <p className='hold'>Время зачисления ~ <b>2</b> минуты</p>
                            <input autoComplete='off' type="number" maxlength="5" max="14999" value={price}  onChange={inputChange} required/>
                        </label>

                        <div className='form__btn'>
                            <button className="form__btn-add" onClick={() => priseAdd(100)} type="button">+ 100₽</button>
                            <button className="form__btn-add" onClick={() => priseAdd(500)} type="button">+ 500₽</button>
                            <button className="form__btn-add" onClick={() => priseAdd(1000)} type="button">+ 1000₽</button>
                        </div>

                        <div className='form__info'>
                            <p><b>Внимание:</b> Указывайте только логин Steam. Лимит в сутки на аккаунт 500$. Минимальная сумма пополнения 300₽. Мы пополняем аккаунты только из стран СНГ региона. <a rel='noreferrer' href="https://t.me/steamballs_bot" target='_blank'>Написать нашей службе поддержки</a></p>
                        </div>
                    </div>
                    <div className='form__block-pay'>
                        <div className={clazzHand}>
                            <div className='modal__inner-handler'>
                                <img src={spinner} alt='Попоплнение баланса стим'/>
                                <h5>Переход на оплату...</h5>
                            </div>
                        </div>
                        <div className={clazz}>
                            <div className='alert__inner'>
                                <svg height="14px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="14px" xmlns="http://www.w3.org/2000/svg" ><path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>
                                Минимальная сумма пополнения 300₽
                            </div>
                        </div>
                        <h1>Проверьте данные</h1>
                        <h2 className='login'>Логин Steam: {login === "" ? '???' : login_validate()}</h2>
                        <p className='prise'>{Number.isNaN(price) ? 300 : price}₽</p>
                        <h3>Сумма с учетом комиссии</h3>
                        <p className='prise-final'>{Number.isNaN(oldp) ? Math.round(300 * 1.025) : newp}₽<span className='prise__line'>{Number.isNaN(oldp) ? Math.round(300 * 1.035) : oldp}₽</span><span className='prise__btn'>−2.0%</span></p>
                        <button  type="submit" className="cont">Продолжить</button>
                        <div className='limts'>Минимальная сумма пополнения - <b>300₽</b><br/>Максимальная - <b>14 999₽</b></div>
                    </div>
                </div>
            </form>
        </main>
    )
}

export default Form;