import './footer.scss';

const Footer = () => {
    return(
        <footer className='footer'>
            <div className='footer__inner'>
                <div className='footer__block'>
                    <div className='footer__adv'>
                        <svg fill="#ccc" viewBox="0 0 512 512" width="17" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M336 240c17.68 0 31.99-14.25 31.99-32s-14.31-32-31.99-32c-17.8 0-32.02 14.25-32.02 32S318.2 240 336 240zM175.1 240c17.8 0 32.02-14.25 32.02-32s-14.22-32-32.02-32c-17.68 0-31.99 14.25-31.99 32S158.3 240 175.1 240zM340 312.6C319.2 337.6 288.5 352 256 352s-63.21-14.25-84.04-39.38c-8.477-10.25-23.61-11.5-33.79-3.125C128 318 126.7 333.1 135.1 343.3c29.91 36 74.11 56.73 120.9 56.73s90.94-20.73 120.9-56.73c8.598-10.12 7.145-25.25-3.027-33.75C363.7 301.1 348.5 302.4 340 312.6zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z"/></svg>
                        <div className='footer__adv-text'>
                            <h1>9 854</h1>
                            <b>ПОПОЛНЕНИЙ</b>
                        </div>
                    </div>
                    <div className='footer__cop'>
                        <h2>STEAMBALL © 2024</h2>
                        <a href='https://vk.com/club226687222' rel="noreferrer" target="_blank">
                            <svg height="19" fill='#ccc' viewBox="0 0 512 512" width="19" xmlns="http://www.w3.org/2000/svg"><title/><path d="M484.7,132c3.56-11.28,0-19.48-15.75-19.48H416.58c-13.21,0-19.31,7.18-22.87,14.86,0,0-26.94,65.6-64.56,108.13-12.2,12.3-17.79,16.4-24.4,16.4-3.56,0-8.14-4.1-8.14-15.37V131.47c0-13.32-4.06-19.47-15.25-19.47H199c-8.14,0-13.22,6.15-13.22,12.3,0,12.81,18.81,15.89,20.84,51.76V254c0,16.91-3,20-9.66,20-17.79,0-61-66.11-86.92-141.44C105,117.64,99.88,112,86.66,112H33.79C18.54,112,16,119.17,16,126.86c0,13.84,17.79,83.53,82.86,175.77,43.21,63,104.72,96.86,160.13,96.86,33.56,0,37.62-7.69,37.62-20.5V331.33c0-15.37,3.05-17.93,13.73-17.93,7.62,0,21.35,4.09,52.36,34.33C398.28,383.6,404.38,400,424.21,400h52.36c15.25,0,22.37-7.69,18.3-22.55-4.57-14.86-21.86-36.38-44.23-62-12.2-14.34-30.5-30.23-36.09-37.92-7.62-10.25-5.59-14.35,0-23.57-.51,0,63.55-91.22,70.15-122"/></svg>
                        </a>
                    </div>
                </div>
                <div className='footer__nav'>
                    <a className="footer__logo" href='https://steamball.fun/'>STEAM<p>BALL</p></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;