import Header from './header/header.js'
import Form from './form/form.js'
import Footer from './footer/footer.js'

import './app.scss'

const App = () => {
    return(
    <div className='content'>
        <Header/>
        <Form/>
        <Footer/>
    </div>
    )

}

export default App