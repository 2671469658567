import { useState } from 'react'

import logo from '../../../img/pngwing.com.png'
import './header.scss'


const Header = () => {
    const [isMenu, setIsMenu] = useState(false)

    const handleMenuActive = () => {
        setIsMenu(!isMenu)
    }

    return (
        <>
        <header className="header">
            <div className='header__inner'>
                <a className="header__logo" href='https://steamball.fun/'><img src={logo} alt='Быстрое пополнение балланса Steam'/>STEAM<span>BALL</span></a>
                <nav className='header__nav'>
                    <a href='https://t.me/steamballs_bot' rel="noreferrer" target="_blank">Поддержка</a>
                    <a href='https://vk.com/topic-226687222_52634681' rel="noreferrer" target="_blank">Отзывы</a>
                    <a href='/'>Пополнение Steam</a>
                    <a href='https://vk.com/club226687222' rel="noreferrer" target="_blank">Сообщество</a>
                </nav>
                <div className='burger' onClick={handleMenuActive}>
                    { isMenu ? 
                    <svg fill="#CFD4DA" height="24px" width="24" id="Layer_1" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" ><path className='svg-anim' d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>
                    :
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className='svg-anim' xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 12H21" stroke="#CFD4DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3 6H21" stroke="#CFD4DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3 18H21" stroke="#CFD4DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    }
                </div>
                {isMenu && (
                    <div className="menu">
                        <a href='https://t.me/steamballs_bot' rel="noreferrer" target="_blank">Поддержка</a>
                        <a href='https://vk.com/topic-226687222_52634681' rel="noreferrer" target="_blank">Отзывы</a>
                        <a href='https://vk.com/club226687222' rel="noreferrer" target="_blank">Сообщество</a>
                    </div>
                )}
            </div>
        </header>
        <div className='promo' href='/'> % Действует скидка на пополнение до <span className='prise__btn'>−2.0%</span></div>
        </>
    )
}


export default Header